.back-success{
    /*background: transparent linear-gradient(180deg, #0C4EFC 0%, #4F3DFF 100%) 0 0 no-repeat padding-box;*/
    background-image: url("../Images/pick_site/inscription_back.png");
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: -460px;
    background-position-y: -220px;
    background-size: 103%;
    margin-top: -30px;
    padding: 110px 0;
}

.small-phone{
    width: 75%;
    margin-bottom: 10px;
    margin-top: 10px;
}
.center-phone.small.col {
    display: grid;
}
.center-phone.big{
    text-align: center;
}

.big-phone{
    height: 500px;
    margin-left: -16%;
}
.col-felicitaion{
    justify-content: center;
    align-self: center;
    text-align: center;
    font-weight: 700;
    color: white;
}


.text-felicitaion{
    font-weight: 700;
    color: white;
    margin-left: 20%;
    margin-right: 15%;
    text-align: left;
}

.row-success{
    align-items: center;
    height: 100%;
}
.hr-felicitation{
    border-color: white;
    margin: 30px 20% 30px 20%;
}

.txt-telecharger{
    text-align: left;
    margin-left: 20%;
    margin-right: 20%;
    color: white;
    font-weight: 400;
    line-height: 27px;
}

button.ant-btn.ios-btn .anticon{
    vertical-align: 0;
    font-size: 20px;
}

button.ant-btn.ios-btn{
    border-radius: 20px;
    width: 120px;
    height: 40px;
    color: #1D1F39;
    box-shadow: 0 6px 10px rgba(255, 255, 255, 0.17);
}
button.ant-btn.ios-btn:hover, button.ant-btn.ios-btn:focus {
    color: #1D1F39;
}
.propos{
    justify-content: center;
    margin-top: 120px;
}

.android-btn{
    margin-left: 40px;
}

.btn-div{
    text-align: start;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 30px;
}

.spinner-btn.spinner-border.text-primary {
    width: 2rem !important;
    height: 2rem !important;
    color: #1D1F39 !important;
}
.spinner-border.text-primary {
    color: #1D1F39 !important;
    width: 3rem !important;
    height: 3rem !important;
}

.date-input{
    background-color: #F1F1F1;
    padding: 7px 13px;
    border-radius: 4px;
    width: 100%;
    border-width: 0;

}
.ant-picker-input > input::placeholder {
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
}
.div-height{
    margin-top: 50px;
}
.pages-container.container {
    padding: 0 50px;
    margin-bottom : 70px
}
.right-dashboard.col{
    padding: 0 5vw;
}
.div-exped{
    margin-top: 22px;
    text-align: center;
}
button.ant-btn.envoyer-msg-map.message {
    margin-top: 20px;
}
.dashboard-pages{
    margin-bottom: 100px;
    min-height: 60vh;
}
textarea#commentaire {
    background-color: #F1F1F1;
    padding: 15px;
    border-radius: 4px;
    height: 100px;
}
button.ant-btn.btn-continue.nouvellexp {
    padding: 20px;
    white-space: normal !important;
    word-wrap: break-word;
    width: auto;
}
.recap-p {
    font-size: 20px !important;
    color: #1D1F39;
    font-weight: 300 !important;
}
.recap h4{
    margin-bottom: 25px;
    font-weight: 400;
}
.downIcon{
    font-size : 35px;
    color : #1D1F39;
    margin-top: 20px;
    margin-bottom: 35px;
}
.rightIcon{
    font-size : 20px;
    color : #1D1F39 !important;
    margin-top: 20px;
    margin-bottom: 35px;
}
button.ant-btn.btn-inscr.annuler {
    margin-top: 25px;
    font-size: 17px;
    padding: 5px 35px;
}
.recap.after h4{
    margin-bottom: 10px;
}
.recap.after.tarif h4{
    font-size: 35px;
    margin-bottom: 10px;
}
.tarif .recap-p {
    font-size: 45px !important;
    font-weight: 500 !important;
}
.after{
    margin-bottom: 40px;
}
button.ant-btn.btn-continue.nouvellexp.recap {
    margin-top: 10px;
    padding: 20px 50px;
}
button.ant-btn.recap.ant-btn-link {
    color: #979797;
    font-size: 15px;
    height: auto;
}
button.ant-btn.recap.ant-btn-link:hover {
    border: none;
}
.vl {
    border-right: 1px solid #707070;
    height: 55px;
    align-self: center;
}

.little-vl {
    border-right: 1px solid #707070;
    height: 35px;
    align-self: center;
}
img.img-mes-exp {
    width: 40px;
    margin-top: -10px;
}
.little-vl {
    text-align: center;
}
.adresses-city.col {
    text-align: center;
    color: #1D1F39;
    font-weight: 200;
    align-self: center;
}
.details {
    text-align: center;
    align-self: center;
}
.details p{
    font-size: 14px;
    font-weight: 500;
    color: #979797;
}
p.data {
    color: #1D1F39;
    font-size: 16px;
    font-weight: 600;
}
.etat{
    background-color: transparent;
    border: 2px solid #1D1F39;
    font-size: 18px;
    font-weight: 500;
    height: auto;
    border-radius: 35px;
    padding: 5px 30px;
    color: #1D1F39;
}
.hr-msg {
    width: 100%;
    border-top: 2px solid rgba(0,0,0,.1);
    margin-bottom: 0;
    margin-top: 5px;
}
.history-msg {
    height: 70vh;
    overflow: auto;
}
.ant-modal-body {
    font-family: 'Poppins';
}
.history-msg {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.history-msg::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
}
.msg-name {
    color: #1D1F39;
    font-weight: 200;
    margin-bottom: 0;
    font-size: 16px;
}
.msg-sended {
    background-color: #1D1F39;
    border-radius: 40px;
    padding: 10px 30px 2px;
}
.txt-msg {
    color: #fff;
    margin-bottom: 0;
    font-size: 13px;
}
.msgRow {
    margin-bottom: 25px;
}
.msg-name.transp{
    text-align: right;
}
.send-msg-btn{
    justify-content: center;
}
.date-msg {
    color: #fff;
    margin-bottom: 0;
    font-size: 9px;
    font-weight: 300;
    float: right;
}
.name.row {
    align-items: center;
}
.header-etat {
    margin-bottom: 30px;
}
.left-border{
    border-left: 2px solid rgb(56, 227, 33);
}
.retour {
    margin-top: -20px;
    padding-left: 45px;
}
.retour span{
    color: #979797;
    font-size: 15px;
    font-weight: 500;
}
.ant-btn:active, .ant-btn.active {
    color: #1D1F39;
    border-color: #1D1F39;
}
.ant-btn:hover, .ant-btn:focus {
    color: #1D1F39;
    border-color: #1D1F39;
}
.etat-modal .ant-modal-title{
    font-family: Poppins;
    color: #979797;
}
.ant-modal-title{
    font-family: Poppins;
}
.etat-modal .ant-modal-header {
    border-bottom: none;
}

.etat-modal .ant-modal-body {
    padding: 0px 24px 20px;
}

.droite-left{
    margin-bottom: -5px;
}
.droite-left {
    padding: 0 15px;
}
.after-etat {
    margin-top: 50px;
}
.circle-indice{
    height : 15px ;
    border: 2px solid #38E321;
    background-color:#fff;
    width :15px;
    z-index:999;
    border-radius: 1000px;
}
.circle-indice-next{
    height : 15px ;
    border: 2px solid #D9D9D9;
    background-color:#fff;
    width :15px;
    z-index:999;
    border-radius: 1000px;
}
.transp-name{
    color: #979797;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 12px;
    margin: 0 0 0 15px;
}
.transp-name.mesexped{
    margin-left: 0;
}
.etatrow {
    margin-top: 15px;
}
.horizontal-line.pic {
    padding-left: 25px !important;
}
.circlecol{
    padding: 0;
    align-self: center;
    margin-left: -8px;
}
.actual-state{
    color: rgb(29, 31, 57);
    font-size: 14px;
    margin: 0 0 0 15px;
    font-weight: 600;
    font-family: 'Poppins';
}
.next-state {
    color: #D9D9D9;
    font-size: 13px;
    margin: 0 0 0 15px;
    font-weight: 500;
}
.horizontal-line{
    padding: 0 !important;
    align-self: center;
}
.note-img {
    width: 90%;
}
span{color: #1D1F39}
.row-search {
    margin-bottom: 25px;
    align-items: center;
}
.hist-title {
    text-align: center;
}
.hist-title .title{
    color: #979797;
    font-weight: 500;
}
span.title-paiement {
    font-weight: 700;
}
span.paiement-data {
    color: #979797;
}
span.paiement-data.paiement {
    font-weight: 700;
}
.paim-data.row {
    align-items: center;
    padding: 15px 0;
}
.paim-title{
    margin-bottom: 25px;
}

.transp-picture{
    width: 60px;
    height: 60px;
    border-radius: 50px;
    object-fit: cover;
}
.transp-titl{
    font-weight: 600;
    font-size: 17px;
}
.hist-price{
    font-size: 18px;
    font-weight: 600;
}
.hist-data{
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 35px;
    margin-top: 10px;
}
.hist-data img{
    width: 20%;
    margin-right: 5px;
    cursor: pointer;
}

.center-vertical{
    display: flex;
    align-items: center;
    height: 100%;
    color: #1D1F39;
    font-weight: 500;
    text-align: left;
}
.adresses-city {
    text-align: center;
}
.right-dashboard-compte .row{
    margin-bottom: 20px;
}
.gif-anim img{
    width: 130px;
}
.gif-anim{
    text-align: center;
}
.gif-anim h5{
    color: #1D1F39;
    font-weight: bold;
    margin-top: 10px;
}
.map.col{
    height: fit-content;
    width: 70vw;
}
.map-view {
    height: 500px !important;
    position: relative !important;
}
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
#GMapsID div div a div img{
    display:none;
}
.gm-style .gm-style-cc a{
    font-size: 10px;
    box-sizing: border-box;
    display: none;
}
.details-button{
    position: absolute;
    top: 35%;
    left: 10%;
}
.msg-button{
    position: absolute;
    top: 48%;
    left: 10%;
}
.upload{
    margin-bottom: 0;
    cursor: pointer;
}
.link-btn {
    background: transparent;
    color: #979797;
}
.little-hr{
    border-top: 2px solid rgba(0,0,0,.1);
    width: 60%;
    margin: 5px auto;
}
.little-hr-bottom{
    border-top: 2px solid rgba(0,0,0,.1);
    width: 15%;
    margin: 5px auto;
}
.actions-compte{
    text-align: center;
    margin-top: 10px;
}
button:focus {
    outline: none !important;
}
.delete-modal{
    width: 40%;
}
a.columnBack.selected.selected1 {
    border-radius: 15px 15px 0 0;
}
button.ant-btn.btn-oui {
    width: 70%;
    background-color: #1D1F39;
    height: auto;
    padding: 8px 0;
    margin-top: 30px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 5px;
}
span.ant-input-password.input-pass.compte.ant-input-affix-wrapper {
    margin-bottom: 20px;
}
.suggestion-item {
    padding: 10px 15px;
    font-size: 14px;
}
.suggestion-item--active {
    padding: 10px 15px;
    background-color: #EAEAEA !important;
    font-size: 15px !important;
}
.input-compte {
    margin-bottom: 20px;
}
.center-btn{
    text-align: center;
}
span.confirm-txt {
    font-weight: 300;
    font-size: 16px;
}
.ant-modal-content {
    padding: 25px 50px;
}
button.ant-btn.btn-non span {
    color: #fff;
}
button.ant-btn.btn-oui span {
    color: #fff;
}
button.ant-btn.btn-non {
    width: 70%;
    background-color: #B38053;
    height: auto;
    padding: 8px 0;
    margin-top: 30px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 5px;
}
.ant-modal-header {
    border-bottom: 1px solid #9C9C9C;
    text-align: center;
}
span.ant-select-selection-item {
    color: #fff;
}
a.columnBack.selected.selected3 {
    border-radius: 0 0 15px 15px;
}
.en-cours{
    padding-left: 10%;
    margin-top: 15px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border: none;
}
button.ant-btn.details {
    border-radius: 15px;
    padding: 7px 20px;
    height: auto;
    font-weight: 600;
    font-size: 15px;
    box-shadow: 0 3px 6px #00000029;
}
button.ant-btn.envoyer-msg-map {
    border-radius: 15px;
    padding: 7px 20px;
    height: auto;
    font-weight: 300;
    font-size: 14px;
    box-shadow: 0 3px 6px #00000029;
}
.details svg{
    margin-top: -5px;
}
.envoyer-msg-map img{
    margin-right: 15px;
    width: 15px;
}
.en-cours span{
    color: #979797;
    font-size: 14px;
    letter-spacing: 0px;
}
svg.valide-colis {
    font-size: 50px;
    color: #32CD32;
    margin-right: 20px;
}
.colis {
    height: 400px;
    border: 2px solid #979797;
    border-radius: 3px;
    padding: 20px 60px;
    margin-bottom: 60px;
}
.colis-signature {
    height: 100px;
    border: 2px solid #979797;
    border-radius: 3px;
    padding: 0;
    margin-bottom: 60px;
}
.spinner-btn.white.spinner-border.text-primary {
    color: white !important;
}
.colis-signature img{
    height: 100%;
}
.title-pic p {
    color: #1D1F39;
    font-size: 20px;
}
.title-pic {
    margin-bottom: 25px;
}
.photo-livraison {
    margin-bottom: 30px;
}
span.title-comment {
    color: #979797;
    font-size: 20px;
    font-weight: 500;
}
span.comment-content {
    color: #979797;
    font-size: 20px;
    font-weight: 200;
}
.comment {
    margin-bottom: 15px;
}
ul.ant-rate {
    margin-top: 45px;
}
.transp-map-img{
    width: 60px;
    border-radius: 50px;
    height: 60px;
    object-fit: cover;
}
.delete-padding{
    align-self: center;
}
button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
img.rate {
    width: 55px;
}
.star-rating {
    margin-top: 50px;
    margin-bottom: 50px;
}
.colis img {
    height: 100%;
}
textarea.ant-input {
    background-color: #F1F1F1;
    border-radius: 5px;
}
.ant-input:hover {
    border-color: transparent;
}
.list{
    text-align: left;
}
span.mention-footer {
    font-weight: 600;
    color: white;
    font-size: 13px;
}
h5.first-title {
    text-decoration: underline;
    margin-left: 10%;
}
.cgu li {
    font-weight: 200;
    color: #656970;
}
.cgu ul {
    list-style-type: none;
}
h5.second-title {
    text-decoration: underline;
    margin-left: 15%;
    margin-top: 0 !important;
}

.cgu ul li:before {
    content: '-';
    position: absolute;
    margin-left: -25px;
}
.cgu h4{
    text-decoration: underline;
    font-size: 20px;
    font-weight: 500;
    color: #656970;
    margin-bottom: 40px;
    margin-top: 40px;
    margin-left: 5%;
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
    color: white;
}
.empty-history {
    height: 35vh;
}
.mentions-legales {
    padding: 20px 15vw 150px;
}
.cookie-container {
    padding: 10px 5vw;
}
.cookie-content {
    margin-right: 40px !important;
    font-size: 13px;
    font-family: 'Poppins';
}
textarea.ant-input.input-contact {
    background-color: white;
}
label.check-cgu.ant-checkbox-wrapper {
    margin: 30px 0;
}


.ElementsApp, .ElementsApp .InputElement {
    font-family: inherit;
}
.mentions-legales p{
    font-family: Poppins;
    font-weight: 200;
    color: #656970;
}
.mentions-legales h5{
    font-family: Poppins;
    font-weight: 500;
    color: #656970;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 15px;
}
button.btn-piece.file {
    padding: 7px 13px;
}
@media only screen and (max-width: 1024px) {
    .picture-prof{
        padding: 0 29% !important;
    }
    .center-phone.big{
        display: none;
    }
    .etat-modal .ant-modal-content {
        padding: 25px 20px;
    }
    .mentions-legales {
        padding: 20px 3vw;
    }
    .map.col {
        width: 94vw;
    }
    .colis {
        height: 300px;
        padding: 20px 35px;
    }
    .suggestion-item span{
        padding: 5px;
        font-size: 12px;
    }
    .suggestion-item--active span{
        padding: 5px;
        font-size: 12px !important;
    }
    .center-phone.small.col {
        justify-items: center;
    }
    .check-cgu span {
        font-size:12px
    }
    .col-felicitaion{
        margin-bottom: 100px;
    }
    span.title-comment {
        font-size: 13px;
    }
    img.rate {
        width: 40px;
    }
    hr.hr-register.hr-dash.top {
        margin-top: 40px;
    }
    button.ant-btn.envoyer-msg-map span{
        font-size: 13px;
    }
    .transp-map-img{
        width: 45px;
        height: 45px;
    }
    button.ant-btn.details span{
        font-size: 13px;
    }
    .details p {
        font-size: 9px;
    }
    .actual-state.trans {
        margin-left: 0;
    }
    .header-etat {
        margin-left: -25px !important;
        margin-right: -25px !important;
    }
    .transp-name{
        margin: 0;
    }
    .gif-anim h5 {
        font-size: 15px;
    }
    .gif-anim .col {
        padding: 0;
    }
    .etat {
        font-size: 0;
        padding: 8px;
    }
    .etat span{
        font-size: 9px;
    }
    .note-img {
        width: 100%;
        margin-top: -10px;
    }
    .back-success{
        /*background: transparent linear-gradient(180deg, #0C4EFC 0%, #4F3DFF 100%) 0 0 no-repeat padding-box;*/
        background-image: url("../Images/pick_site/back_violet.png");
        background-repeat: initial;
        padding-bottom: 100px;
    }
    .text-felicitaion {
        margin-left: 50px;
        margin-right: 50px;
        margin-top: 50px;
    }
    .txt-telecharger {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 18px;
    }
    .btn-div {
        margin-left: 50px;
        margin-right: 50px;
        text-align: center;
        margin-top: 40px;
    }
    .big-phone {
        height: 420px;
        margin-top: 90px;
        margin-bottom: 30px;
    }
    .small-phone{
        height: 420px;
        width: auto;
        margin-bottom: 40px;
    }
    .center-phone{
        text-align: center;
    }
    .hr-felicitation {
        border-color: white;
        margin: 30px 50px;
    }
    .msg-button {
        top: 65%;
    }
    .details-button {
        top: 50%;
    }
    .en-cours{
        margin-top: 15px;
    }
    .etat-modal .ant-modal-body {
        padding: 0px 15px 20px;
    }
    .actual-state {
        font-size: 12px;
    }
    .ant-modal-close-x{
        transform: scale(2);
    }
    .cookie-content {
        margin-right: 15px !important;
    }
    .btn-wrap {
        width: 100%;
        text-align: center;
    }
    .horizontal-line.pic {
        padding-left: 20px !important;
        margin-right: 2px;
    }
    .retour {
        padding-left: 0;
    }
    .link-btn span{
        font-size: 15px;
    }
    button.ant-btn.envoyer-msg-map.message span{
        font-size: 14px;
    }
    .history-msg {
        height: 75vh;
    }
    button.ant-btn.btn-oui span {
        font-size: 12px;
    }
    button.ant-btn.btn-non span {
        font-size: 12px;
    }
    .actions-compte {
        margin-bottom: 30px;
    }
    span.title-paiement {
        font-size: 12px;
    }
    span.paiement-data {
        font-size: 11px;
    }
    .paim-data.row {
        padding: 10px 0;
    }
    .hist-price{
        font-size: 12px;
    }
    .rightIcon{
        font-size : 12px;
        margin-top: 30px;
    }
    .delete-padding{
        padding: 0 3px !important;
    }
    img.img-mes-exp {
        width: 15px;
        margin-top: 0;
    }
    .adresses-city{
        padding: 0 !important;
    }
    .little-vl {
        padding: 0 !important;
        height: 25px;
    }
    .transp-picture{
        width: 35px;
        height: 35px;
    }
    .transp-titl{
        font-size: 8px;
    }
    span{
        font-size: 8px;
    }
    .row-search{
        margin-left: -5px;
        margin-right: -35px !important;
    }
    .hist-title{
        padding: 0 3px !important;
        text-align: center;
    }
    .hist-data img {
        width: 30%;
        margin-top: 15px;
    }

    .vl{
        padding: 0 3px !important;
        height: 50px;
    }
    .create-account {
        padding: 15px 0;
        text-align: center;
    }
    .pic-name{
        align-items: center;
    }
    .create-account-row {
        padding: 12px 0;
    }
    .div-height{
        margin-top: 0;
    }
    button.ant-btn.btn-continue{
        margin-top: 20px;
    }
    button.ant-btn.btn-continue span{
        font-size: 20px
    }
    .pass-oublie button.ant-btn.btn-continue{
        margin-top: 40px;
    }
    .inscription.container-fluid {
        padding: 0 50px;
    }
    .file-title{
        font-size: 13px;
    }
    .dashboard-pages{
        max-width: 86vw;
    }
    .nouvelle-exp {
        margin-bottom: 45px;
    }
}
