.label-contact{
    font-size: 15px;
    font-weight: 600;
    margin-top: 55px;
    margin-bottom: 12px;
}

input.input-contact {
    width: 400px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0px 15px #00000029;
    height: 40px;
    padding: 0 20px;
}

textarea.ant-input.input-contact {
    /* width: 400px; */
    width: 600px;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0px 15px #00000029;
    min-height: 80px;
    padding: 15px 20px;
}

button.btn-contact {
    height: 40px;
    background-color: transparent;
    border: 0;
    border-radius: 30px;
    border: none;
    box-shadow: 0 0px 15px #00000029;
    font-size: 17px;
    font-weight: 500;
    margin-top: 50px;
    padding: 0 20px;
}

.contact-bottom{
    justify-content: center;
    align-content: center;
    background-color: #F6F6F6;
    height: 100px;
    margin-top: 60px;
}

.contact-bottom p {
    color: #2B2857;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.contact-bottom a {
    color: #2B2857;
    font-size: 17px;
    font-weight: 300;
}

.succes-contact {
    padding: 40px;
}

.succes-contact p{
    margin-bottom: 0
}

@media only screen and (max-width: 768px) {
    input.input-contact{
        width: 95%;
    }
    .contact-bottom{
        height: 140px;
        padding: 0px 80px;
    }
}