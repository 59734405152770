* {
    box-sizing: border-box;
}

input,
button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

.Form  {
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    height: 100%;
}



@keyframes fade {
    from {
        opacity: 0;
        transform: scale3D(0.95, 0.95, 0.95);
    }
    to {
        opacity: 1;
        transform: scale3D(1, 1, 1);
    }
}

.Form {
    animation: fade 200ms ease-out;
}

.FormGroup {
    margin: 0 15px 20px;
    padding: 0;
    border-style: none;
    background-color: #F1F1F1;
    will-change: opacity, transform;
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #F1F1F1;
    border-radius: 4px;
}

.FormRow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 15px;
}

.FormRow:first-child {
    border-top: none;
}

.FormRowLabel {
    width: 15%;
    min-width: 70px;
    padding: 11px 0;
    color: #979797;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes void-animation-out {
    0%,
    to {
        opacity: 1;
    }
}
.FormRowInput:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}

.FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #979797;
    background-color: transparent;
    animation: 1ms void-animation-out;
}

.FormRowInput::placeholder {
    color: #979797;
}

.StripeElement--webkit-autofill {
    background: transparent !important;
}

.StripeElement {
    width: 100%;
    padding: 11px 15px 11px 0;
}


.SubmitButton.SubmitButton--error {
    transform: translateY(15px);
}
.SubmitButton.SubmitButton--error:active {
    transform: scale(0.99) translateY(15px);
}

.SubmitButton:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: #7795f8;
    box-shadow: none;
}

.ErrorMessage {
    color: #000;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    font-size: 13px;
    margin-top: 0px;
    width: 100%;
    opacity: 0;
    animation: fade 150ms ease-out;
    animation-delay: 50ms;
    animation-fill-mode: forwards;
    will-change: opacity, transform;
}

.ErrorMessage svg {
    margin-right: 10px;
}

.Result {
    margin-top: 50px;
    text-align: center;
    animation: fade 200ms ease-out;
}

.ResultTitle {
    color: #000;
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 17px;
    text-align: center;
}

.ResultMessage {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.6em;
    text-align: center;
}

.ResetButton {
    border: 0;
    cursor: pointer;
    background: transparent;
}
.price.row {
    margin-right: 7%;
    justify-content: right;
    margin-bottom: -30px;
}
.price h5 {
    font-size: 45px;
    color: #1D1F39;
}
.price span {
    display: inline-block;
    align-self: flex-end;
    color: #1D1F39;
    margin-bottom: 15px;
    font-size: 15px;
    margin-left: 12px;
}
@media only screen and (max-width: 1024px) {
    .price.row {
        margin-right: 0;
        justify-content: center;
        margin-bottom: 25px;
    }
    .price h5 {
        margin-bottom: 0;
    }
    .price span {
        margin-bottom: 5px;
    }
}
