.create-account {
    padding: 25px 80px;
}
.create-account-row {
    padding: 12px 80px;
}

.input-register{
    background-color: #F1F1F1;
    padding: 7px 13px;
    border-radius: 4px;
}
span.ant-input-password.input-pass.ant-input-affix-wrapper{
    background-color: #F1F1F1;
    padding: 0 13px;
    border-radius: 4px;
    border: 0;
}
.input-pass input.ant-input{
    background-color: #F1F1F1;
}

.input-pass.ant-input-suffix {
    margin-left: 4px;
    align-self: center;
}
.create-account h3{
    font-weight: 400;
}

.input-register::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
    font-family : Poppins;
}

.input-register::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
    font-family : Poppins;
}

.input-register::-ms-input-placeholder { /* Microsoft Edge */
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
    font-family : Poppins;
}

.input-pass input.ant-input::placeholder{
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
}

.input-pass input.ant-input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
}

.input-pass input.ant-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #979797;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
}

.input-col{
    margin-right: 80px;
}

.input-register {
    border-width: 0;
    width: 100%;
}

.input-col p{
    color: #979797;
    font-weight: 300;
}

.input-icon{
    right: 30px;
    position: absolute;
    bottom: 0;
    top: 9px;
    color: #1D1F39;
    width: 18px !important;
    height: 20px;
}
.input-icon.expedition{
    top: 58px;
}

.btn-piece .ant-select-selector{
     height: 38px !important;
     padding: 2px 10px !important;
}

.btn-piece .ant-select-arrow {
    top: 41%;
    right: 18px;
    color: #fff;
    font-size: 18px;
}
button.ant-btn.btn-continue{
    width: 260px;
    background-color: transparent;
    border: 1px solid #1D1F39;
    font-size: 25px;
    font-weight: 500;
    height: auto;
    border-radius: 35px;
    padding: 10px 0;
    margin-top: 50px;
    color: #1D1F39;
}
button.ant-btn.btn-continue:hover, button.ant-btn.btn-continue:focus {
    color: #1D1F39;
    box-shadow: 0px 3px 6px #00000029;
}

/* *************************************  old css ******************************* */
.input-text {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #0C4EFC;
    font-size: 16px;
}

.back-mobile-reg{
    background-image: url("../Images/back_register_mobile.png");
    background-repeat: no-repeat;
    height: 370px;
    padding: 40px 40px 0;
    background-size: 100% 360px;
}

.register_mobile{
    margin-top: -10px;
}


.regoingez{
    font-size: 32px;
    font-family: OverPass, sans-serif;
    letter-spacing: 0;
    color: #0C4EFC !important;
    font-weight: 900;
}

.radio-btn{
    margin-right: 50px;
    display: inline-flex;
    align-items: center;
}


.register-txt{
    color: #979797;

}
.msg-register{
    font-weight: 300;
    font-size: 15px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #E2E2E2;
    opacity: 1; /* Firefox */
    font-weight: 300;
    font-size: 16px;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #E2E2E2;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #E2E2E2;
}

input.PhoneInputInput {
    border-width: 0 0 1px;
    font-size: 16px;
}

.download-file{

}

.label-file {
    cursor: pointer;
    color: #0C4EFC;
    font-family: 'Roboto';
    font-size: 17px;
}

.label-avatar{
    color:     #74869E;
    font-family: Roboto Regular;
    font-size: 17px;
}

.label-radio{
    color: #74869E;
    font-size: 17px;
}

.label-file:hover {
    color: #25a5c4;
}

.border-input{
    width: 100%;
    border: 0 !important;
    border-bottom: 1px solid #0C4EFC !important;
    padding: 0 !important;
}

.border-input .ant-select-selector{
    border: 0 !important;
    padding: 0 !important;
}


.input-file {
    display: none;
}

.avatar{
    margin-right: 10px;
    cursor: pointer;
}

.terms{
    width: auto;
    margin-right: 20px;
}

select#langue option[value="french"]   { background-image:url("../Images/french-flag.png");   }
select#langue option[value="spain"] { background-image:url("../Images/Spain_flag.png"); }
select#langue option[value="english"] { background-image:url("../Images/Flag_Britain.png"); }

/* **** for piece identite ******* */
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
}

.btn-piece {
    background: #1D1F39;
    border-radius: 4px;
    padding: 0;
    font-size: 15px;
    color: #ffffff;
    border-color: transparent;
    font-weight: 200;
    width: 100%;
}

.tree-point{
    font-size: 40px;
    margin-top: -30px;
    margin-bottom: -15px !important;
    float: right;
}

.btn-piece label{
    margin-bottom: 0;
}

.upload-btn-wrapper input[type=file] {
    height: 43px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 550px;
    cursor: pointer;
}
/* *********************** */
.btn-valider{
    background-color: #1D1F39;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    border: none;
    color: #FFFFFF;
    padding: 8px 40px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.middle-center{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.error{
    color: #D8000C;
    font-size: 14px;
}

.consignes{
    color: #A2A2A2;
    font-family: "Arial", serif;
    font-size: 11px;
}

.ant-tooltip {
    max-width: 750px;
}
.ant-tooltip-inner {
    min-width: 750px !important;
    color: #979797 !important;
    background-color: white !important;
    border-radius: 27px !important;
    padding: 16px 25px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

/* ************* MOBILE VER *****************/

@media only screen and (max-width: 1024px) {
    .input-col{
        margin-right: 0;
        margin-bottom: 20px;
    }
    .btn-piece .ant-select-arrow {
        top: 55%;
    }
    .ant-picker{
        background-color: #F1F1F1 !important;
        padding: 7px 13px !important;
        border-radius: 4px !important;
        border: none !important;
    }

    .input-col p{
        margin-bottom: 0px;
    }
    .upload-btn-wrapper{
        margin-bottom: 10px;
    }
    .btn-piece {
        width: 100%;
    }
/* ********************** OLD CSS **********************/
    .regoingez{
        color: white !important;
        font-size: 20pt;
        font-weight: 900;
        margin-bottom: 20px;
    }

    .label-radio {
        font-size: 18px;
        font-weight: 500;
    }
    .bell-number {
        font-size: 14px;
        margin-left: -19px;
        color: wheat;
        margin-top: 4px;
    }

    .ant-tooltip.ant-tooltip-placement-topRight {
        left: 5vw !important;
    }

    .ant-tooltip-inner {
        min-width: 90vw !important;
    }
    .telecharger{
        font-family: 'Overpass';
        font-size: 22px;
        font-weight: 700;
        color: #007bff;
        margin-bottom: 0;
    }




    .country > .ant-select-selector{
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 1px solid #0C4EFC !important;
        padding: 0 3px !important;
    }

    .register-txt{
        color: #979797;
        font-weight: 500;
    }

    .btn-piece-mob {
        padding: 8px 30px;
        font-size: 16px;
    }

    .ant-picker-input > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #979797 !important;
        opacity: 1; /* Firefox */
        font-weight: 300;
        font-size: 16px;
        font-family : Poppins;
    }
    
    .ant-picker-input > input::-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #979797;
        opacity: 1; /* Firefox */
        font-weight: 300;
        font-size: 16px;
        font-family : Poppins;
    }
    
    .ant-picker-input > input::-ms-input-placeholder { /* Microsoft Edge */
        color: #979797 !important;
        opacity: 1; /* Firefox */
        font-weight: 300;
        font-size: 16px;
        font-family : Poppins;
    }

}

.form-group {
    margin-bottom: 20px;
}
.form-group.forget {
    margin: 0 20px;
}

