.columnBack{
    padding-top: 22px;
    padding-bottom: 22px;
    width: 100%;
}
.vericalNav a h5{
    color: #1D1F39;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 15px !important;
    font-family: 'Poppins';
}
.vericalNav {
    margin-top:100px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 15px;
    width: 210px !important;
}
.selected {
    background: #1D1F39;
}
.a.columnBack.selected.selected3 {
    border-radius: 0 0 15px 15px;
}
.vericalNav .selected h5{
    color: #FFF;
}
.vericalNav .row{
    text-align: center;
}
/* ***************************** OLD CSS *****************************/


.vertical{
    background-color: #7464FD;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}




.ellipse{
    float: right;
    margin-top: -35px;
    margin-right: -15px;
    display: none;
}
.selected1 .ellipse1{
    display: block;
}

.selected2 .ellipse2{
    display: block;
}

.selected3 .ellipse3{
    display: block;
}

.selected4 .ellipse4{
    display: block;
}

.bell-number{
    font-size: 12px;
    margin-left: -20px;
}
@media only screen and (max-width: 1024px) {
    .bell-number {
        font-size: 12px;
        margin-right: -28px;
        width: 25px;
        text-align: center;
        float: right;
        margin-top: 4px;
    }
    .vericalNav{
        display: none;
    }
}
