.expedieteur-connect {
    font-size: 40px;
    font-weight: 100;
    margin-top: 120px;
}

.input-login.row{
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.pass-oublie {
    text-align: center;
}

.ant-input-suffix {
    place-self: center;
}

.pass-oublie a{
    color: #1D1F39;
    font-size: 14px;
    font-weight: 500;
}
.h5-bottom{
    margin-bottom: 0;
}
.col-ou{
    text-align: center;
    align-self: center;
}
.row-ou{
    margin-top: 20px;
    margin-bottom: 20px;
}

/* ********************** OLD CSS ***********************/
.pass-wrapper {
    position: relative;
    display: flex;
    margin-bottom: 14px;
}
.input-login{
    background-color: transparent;
}

.connect{
    color: #0C4EFC !important;
    font-family: 'Overpass';
    font-weight: 700 !important;
}

.txt-after-connect{
    color: #565656;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 30px;
}

.se-souvenir{
    width: auto;
    margin-right: 10px;
}

i {
    position: absolute;
    top: 18%;
    right: 5%;
}
i:hover {
    color: #00fcb6;
    cursor: pointer;
}


input:focus {
    border-color: green;
}

.back-logi{
    /*background: transparent linear-gradient(180deg, #0C4EFC 0%, #4F3DFF 100%) 0 0 no-repeat padding-box;*/
    /* background-color: #B38053; */
    width: 200px !important;
    border-radius: 14px 0 0 14px;
    background-size: 510px;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: -80px;
    background-position-x: -230px;
}

.verif_code {
    margin-top: 50px;
    margin-bottom: 10px;
}

.spinner-border{
    width: 1rem !important;
    height: 1rem !important;
}

@media only screen and (max-width: 1024px) {
    .input-login{
        background-color: transparent;
        font-size: 22px;
    }
    .input-login.row{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }
    .connect{
        color: #0C4EFC !important;
        font-family: 'Overpass';
        font-weight: 600 !important;
        font-size: 2.1rem;
    }
    .verif_code {
        width: auto !important;
    }

    .txt-after-connect {
        font-weight: 500;
    }

    .styles_react-code-input__CRulA > input {
        width: 45px !important;
    }
}


