hr.hr-register.hr-dash{
    width: 100%;
    margin-bottom: 35px;
}
.div-select-exp{
    margin-top: 60px;
}

/* **************************** OLD CSS *******************************/
.profile{
    margin-top: 20px;
    padding: 0 120px;
}

.ant-modal-content {

    box-shadow: 0px 6px 10px #0000001C !important;
    border-radius: 14px !important;
}
.input-profile {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #0C4EFC;
    width: 100%;
    margin-bottom: 15px;
    font-size:17px;
    color: #1E90FF;
    font-family: "Roboto";
    padding-left: 10px;

}


.radio-container {
    box-sizing: border-box;
    font-family: ‘Open Sans’, sans-serif;
    font-size: 13px;
    line-height: 30px;
    margin: 0;
    outline: 0;
    overflow: hidden;
    padding: 0;
}


.radio-container input {
    box-sizing: border-box;
    margin: 0;
    outline: 0;
    padding: 0;
    position: relative;
    top: 9px;
    vertical-align: top;
}

.btn-valider-profile{
    background: transparent linear-gradient(to right, #FFDA74 , #FF1100 );
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    border: none;
    color: #FFFFFF;
    padding: 8px 40px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

button.btn.btn-link.pass-change {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 35px;
    float: left;
}

.input-file {
    display: none;
}

.txt{
    color: #0C4EFC;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 50px;
}

.und-menu{
    border-bottom:2px solid white;
    width:100%;
    display: flex;
}

.row-und-menu{
    margin: 30px 15px !important;
    border-bottom: 2px solid #DFE7F5;
    padding-bottom: 20px;
}

.pad-mob{
    padding: 40px !important;

}

.custom-file-upload {
    cursor: pointer;
    color: #1890ff;
    font-size: 15px;
}

#profile-img{
    box-shadow: 0 2px 5px #00000029;

}

button.btn.btn-link.pass-change {
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 35px;
    text-align: left;
    width: 100%;
}

.pencil-edit{
    margin-left: 6vw;
    margin-top: -9vh;
}

/* ******************************** Mobile Vers ***********************/
@media only screen and (max-width: 768px) {
    button.btn.btn-link.pass-change {
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 35px;
        float: left;
    }
    .profile{
        padding: 0px;
    }
    hr.hr-register.hr-dash.mobile {
        display: none;
    }
    .div-select-exp{
        margin-top: 0;
    }
}
