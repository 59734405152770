.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.inscri-expediteur.row{
  text-align: center;
}

.inscri-expediteur hr{
  width: 88%;
  margin-top: 30px;
  border-top: 2px solid rgba(0,0,0,.1);
}

.page-layout{
  margin-bottom: 100px;
}

.hr-register{
  width: 88%;
  border-top: 2px solid rgba(0,0,0,.1);
  margin-top: 50px;
}
button.ant-btn.btn-inscr {
  background-color: #1D1F39;
  color: #fff;
  border-radius: 60px;
  height: auto;
  padding: 20px 45px;
  margin-top: 70px;
  font-size: 20px;
}
button.ant-btn.btn-inscr span{
  color: #fff;
}


.inscri-expediteur p{
  font-size: 40px;
  font-weight: 100;
}

.inscri-expediteur .row h2{
  font-weight: 600;
  font-size: 38px;
}

.App {
  font-family: Poppins;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.spinner-center-country {
  text-align: center;
  height: 100vh;
  overflow-y: hidden;
  display: block;
  /* margin: auto; */
  /* position: relative; */
  /* top: 66%; */
  /* -webkit-transform: translateY(-50%); */
  /* transform: translateY(50vh); */
  padding-top: 50vh;
  padding-bottom: 50vh;
}

.overpass-fam{
  font-family: 'Overpass', sans-serif;
}

.Roboto-fam{
  font-family: 'Roboto', sans-serif;
}

.ant-modal-root {
  font-family: 'Roboto';
}

.ant-select-selector {
  z-index: 100;
}

input[type=radio] {
  width: 20px !important;
  height: 20px;
  margin-right: 15px !important;
}

input[type=checkbox] {
  width: 15px;
  height: 15px;
}
.ant-select-selector {
  background-color: transparent !important;
}

.no-auth {
  position: absolute;
  top: 6%;
}

@media only screen and (max-width: 1024px) {
  button.ant-btn.btn-inscr span{
    font-size: 20px;
  }
  .ant-picker-suffix {
    transform: scale(2);
    margin-bottom: 7px;
  }
  .slick-prev, .slick-next{
    padding-top: 2px;
  }
  .no-auth{
    position: absolute;
    top: 2%;
    padding: 65px;
  }
  .inscri-expediteur.row {
    margin-top: 0;
    padding: 15px 20px;
  }
  .hr-register{
    margin-top: 15px;
  }
  .inscri-expediteur h2{
    font-weight: 600;
    font-size: 24px;
  }
  .inscri-expediteur p{
    font-size: 30px;
    font-weight: 100;
  }
  button.ant-btn.btn-inscr {
     margin-top: 40px;
   }
   span.ant-select-selection-item{
     font-size: 14px;
   }
   span.ant-select-arrow {
    transform: scale(2);
}
}

