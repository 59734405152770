.mainDiv{
    background-color: #1D1F39;
    padding: 25px 0;
    color: white;
    text-align: center;

}

.footer-txt {
    font-size: 14px;
    font-family: poppins, sans-serif;
    font-weight: 400;
    margin-bottom: 0;
}

.footer-txt a{
    color: #fff;
}

.under-brand{
    margin-bottom: 30px;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 200;
}

.Hwhite{
    color: white;
    margin-bottom: 20px;
    font-size: 1.2rem !important;
    font-weight: 600;
}


.parrainage .ant-card-body{
    padding: 15px;
}

.max{
    max-width: 90%;
}

.footer_link {
    font-size: 13px;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    color: #fff;
}

.footer_link:hover {
    color: #fff;
}

.logo-title{
    margin-bottom: 0px;
    color: white;
    margin-top: 20px;
    font-size: 22px !important;
    font-family: 'Roboto';
}

@media only screen and (max-width: 768px) {
    .mainDiv{
        margin-top: 0;
        padding: 25px 30px;
    }

    .Hwhite{
        color: white;
        margin-bottom: 10px;
    }

    .under-brand{
        margin-bottom: 30px;
        font-size: 12px;
        font-family: 'Poppins';
        font-weight: 100;
    }

    .footer_link {
        font-weight: 300;
    }
}


