.ant-dropdown-menu{
    background-color: #1D1F39 !important;
    border-radius: 0 0 25px 25px !important;
    padding: 10px 0 !important;;
    text-align: center;
}
.dropdown-nav{
    padding: 5px 15px !important;
    color: #FFFFFF !important;
    text-align: -webkit-center;
}
.ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a{
    color: #FFFFFF !important;
    margin: 0;
}
.ant-dropdown{
    width: 190px;
    text-align-last: center;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent !important;
}
.ant-dropdown-menu-item > a:hover, .ant-dropdown-menu-submenu-title > a:hover {
    color: #B38053 !important;
    border-bottom: 2px solid #B38053;
    transition: border-bottom .5s ease-in-out;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

/* ************************* OLD ************************/


.main-nav{
    height: 110px;
    padding: 10px 70px 0 200px !important;
}

a:hover {
    text-decoration: none !important;
    color: #133A50 !important;
}

.main-nav-mobile{
    padding-right: 0;
    padding-left: 20px;
    height: 70px;
    align-content: center;
    align-items: center;
    padding-top: 10px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.logo-brand{
    font-size: 20px;
    margin-bottom: 0;
    color: #133A50;
    font-weight: 600;
    font-family: 'Roboto';
}

.name{
    margin: 10px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: 1px;
    color: #75769A;
}



.ant-btn {
    border-color: transparent;
}

.navbar-mobile {
    display: flex;
    justify-content: right !important;
    align-items: center;
    border-radius: 4px;
    margin-top: 10px;
}

.ant-drawer-body {
    padding: 0 !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}

.ant-menu {

    background: transparent;
}

a.menu-a {
    color: #fff;
    font-size: 14px;
    font-weight: 200;
    font-family: 'Overpass';
    margin-top: -10px;
}

.back-drawer{
    background-color: #fff;
    height: 79vh;
}

.menu-a {
    color: #fff;
    font-size: 20px;
    font-weight: 200;
    font-family: 'Overpass';
}

.mobile-men.row {
    border-bottom: solid 1px #B38053;
    padding: 25px 45px;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 100; /* this is optional and should be different for every project */
}


.nav-mobile{
    color: white;
    font-size: 18px;

}



.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0;
}

.navbar__link {
    margin-right: 80px;
    font-size: 15px;
    font-family: 'poppins', sans-serif;
    cursor: pointer;
    text-decoration: none;
    color: #1D1F39;
    font-weight: 500;
}

.navbar__link:hover {
    transform: translateY(-2px);
}

.navbar__link--active {
    border-bottom: 2px solid #1D1F39;
    transition: border-bottom .5s ease-in-out;
}
.ant-dropdown-menu-item > a.active{
    color: #B38053 !important;
    border-bottom: 2px solid #B38053;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}


@media only screen and (max-width: 768px) {
    .navbar__link:hover {
        transform: none;
        color: #fff;
    }
    a:hover {
        color: #fff;
    }
    .navbar__link{
        color: #1D1F39;
        font-weight: 500;
        margin-right: 0;
    }
    .navbar__link span{
        font-size: 15px;
    }
    a.menu-a span{
        font-size: 14px;
    }
    li.ant-menu-item.ant-menu-item-only-child {
        height: auto;
    }
}

