.vertical-align {
    display: flex;
    padding: 0px 15px;
    background-image: url("../../Images/back_img_home_pick.png");
    background-repeat: no-repeat;
    background-position-y: -70px;
    background-position-x: 30px;
    background-size: 100vw;
    height: 61vw;
}

.numbers{
    background-image: url("../../Images/back_numbers_pick.png");
    height: 190px;
    background-repeat: no-repeat;
    background-position-y: -80px;
    background-position-x: 10px;
    background-size: 105vw;
}

.phones{
    background-image: url("../../Images/pick_site/banniere.png");
    height: 600px;
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 30px;
    background-size: 100vw 600px;
    margin-top: 100px;
}
.newsletter{
    background-image: url("../../Images/back_newsletter_pick.png");
    height: 500px;
    background-repeat: no-repeat;
    background-position-y: -200px;
    background-position-x: 30px;
    background-size: 100vw;
    place-content: center;
}

.newsletter h3{
    font-size: 32px;
    font-weight: 400;
    color: #fff;
}

.newsletter input.ant-input{
    height: 50px;
    margin-top: 25px;
}

.newsletter p{
    font-size: 16px;
    font-weight: 200;
    color: #fff;
    margin-top: 15px;
}
.newsletter input.ant-input::placeholder{
    font-size: 13px;
    font-weight: 300;
    color: #656970;
}

.newsletter button.ant-btn {
    border-radius: 30px;
    height: 35px;
    padding: 0 22px;
    font-size: 17px;
    font-weight: 500;
    color: #1D1F39;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.facon-livraison{
    text-align: center;
    padding: 20px;
    background-color: #1D1F39;
}
.estimation {
    font-weight: 500 !important;
    margin-left: 20px;
    margin-top: 25px !important;
}
.phoneRight {
    align-self: center;
}
p.explic-estim {
    margin-left: 10px ;
    font-size: 10px !important;
    font-weight: 300 !important;
    margin-top: 10px !important;
}
.estimation-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}
button.ant-btn.btn-oui.valider{
    width: auto;
    margin-top: 0;
    padding: 7px 25px;
    border-color: #1D1F39;
    margin-left: 25px;
}

.facon-livraison h4{
    margin-bottom: 0;
    font-family: 'Poppins';
    color: #FFFFFF;
    font-weight: 100;
    font-size: 22px;
    word-spacing: 5px;
}
.count-number {
    font-size: 40px;
    font-family: 'Poppins';
    font-weight: 700;
    color: #fff;
    margin-left: -41px;
}
.ellipse-number{
    width: 49px;
    margin-top: -32px;
}



.left-res{
    padding: 25px;
    font-size: 18px;
}

.center-home{
    width: 100%;
    text-align: -webkit-center;
    text-align: center;
}

.marg{
    margin-bottom: 50px;
    margin-top: 20px;
}

.avis{
    width: 80%;
    box-shadow: 0 2px 5px #00000029;
    padding: 0 20px;
    margin: 30px;
}

.montant-avis{
    margin-top: 10px;
    margin-bottom: 10px;
}

.title-avis{
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 5px;
}



.img-home{
    margin-bottom: 20px;
    width: 5vw;
}


.card-res{
    box-shadow: 0 2px 5px #00000029;
    border-radius: 10px;
    width: 25vw;
    font-size: 16px;
    margin: 30px 10px;
}


.card-res .ant-card-body{
    padding: 0;
}

.winners{
    background-image: linear-gradient(to right top, #1133E3, #11268E);
    border-radius: 0 10px 10px 0;
    max-width: 40%;
}

.travelcompany{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: auto;
}
.think
{
    position: absolute;
    margin: -230px 0 0;
}

.title-hom{
    font-weight: 600;
    font-family: Poppins, serif;
    font-size: 35px;
    color: #1D1F39;
    z-index: 99999;
}
.apropos-txt {
    padding: 0px 480px;
    font-size: 16px;
    font-family: Poppins;
    color: #5f5f5f;
    text-align-last: center;
    font-weight: 200;
    z-index: 9999;
}

.title-col{
    color: #707070;
    font-weight: 700;
    font-family: OverPass;
    font-size: 1.25rem !important;
    margin-bottom: 15px;
}

.big-title{
    font-size: 26px;
    font-weight: 700;
}

.txt-col{
    color: #707070;
    font-family: OverPass;
    font-weight: 300;
    font-size: 14px;
}

.phone-white{
    padding-left: 250px;
    width: 100%;
}
.phone_white_collapse{
    padding-left: 40%;
    width: 100%;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    font-size: 16px;
    color: #27264D;
}
img.ant-collapse-arrow {
    width: 20px;
    margin-top: 0px;
}
.phoneRight {
    align-self: center;
}
span.anticon.anticon-down-circle.ant-collapse-arrow {
    font-size: 20px !important;
    color: rgb(255, 153, 165) !important;
}
.collapse-shadow {
    padding-right: 250px;
    margin-top: 100px;
}
.collapse-shadow .ant-collapse-borderless {
    background-color: #fff;
    border: 0;
}

.collapse-shadow .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0;
}
.collapse-shadow .collapse-txt {
    padding: 0 15px;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-image: -webkit-linear-gradient(178deg, rgb(255, 153, 165) 0%, rgb(255, 96, 163) 100%) !important;
    color: white !important;
    border-radius: 10px !important;
}

.collapse-shadow .ant-collapse-item {
    box-shadow: 0 10px 15px #0000002e;
    margin-bottom: 25px;
    border-radius: 10px !important;
}

.collapse-shadow .ant-collapse-header {
    border-radius: 10px 10px 0 0 !important;
    height: 65px;
    padding: 21px !important;
    font-size: 14px;
    font-family: 'Poppins';
    color: #2B2857;
    font-weight: 800;
    padding-left: 30px !important;
}
.inscription-home{
    background-color: #F6F6F6;
    justify-content: center;
    padding-bottom: 35px;
}
.inscription-home p{
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
    font-weight: 200;
    font-size: 18px;
    color: #656970;
}

.inscription-home button.ant-btn {
    border-radius: 30px;
    height: 45px;
    box-shadow: 0 5px 15px #0000002e;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 500;
    color: #1D1F39;
}


.inscription-home .center {
    text-align: center;
}

.inscription-home .title-hom{
    margin-top: 40px;
}

p.explication {
    font-size: 17px;
    line-height: 23px;
    margin-top: 25px;
}

.solution-livraison{
padding-right: 275px;
}

.solution-livraison img{
    width: 45px;
    margin-top: 50px;
}

.solution-livraison .ant-collapse-borderless{
    background-color: transparent;
    margin-bottom: 20px;
}
.solution-livraison .ant-collapse-item {
    border: 0 !important;
}

.solution-livraison h3 {
    font-size: 28px;
    font-family: 'Poppins';
    font-weight: 500;
    color: #1D1F39;
    margin-top: 30px;
    text-align: left;
}

.solution-livraison .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins';
    color: #28274C;
    letter-spacing: 0px;
}

.collapse-txt{
    font-size: 16px;
    font-family: Poppins;
    color: #5f5f5f;
    font-weight: 200;
}

.txt-number{
    color: #fff;
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 600;
}

.vague{
    margin-left: -100px;
}

.bot{
    margin-bottom: -240px;
}

.solid{
    border-bottom: 2px solid #1548FC;
    width: 40px;
    margin-top: 2px;
    border-top: 0;
}

.site-card-wrapper {
    padding: 0px 10vw;
    margin-bottom: 13vh;
}

.img-point{
    margin-right: 15px;
}

.savoir-btn{
    border-radius: 30px;
    margin-top: 15px;
    padding: 7px 35px;
    box-shadow: 0 10px 15px #0000002e;
    background-image: linear-gradient(to bottom, #7464FD 0%, #5851EA 51%, #5851EA 100%);
    border-color: #007bff00;
}


.Back2{
    background-repeat: no-repeat;
    /* background-position-x: 40px; */
    background-position-y: -100px;
    background-size: 108%;
    /* padding: 250px 0; */
    padding-bottom: 150px;
}


.slider-rate{
    text-align-last: center;
    margin-left: 35px;
    margin-right: 70px;
    margin-top: 7px;
    font-family: 'Overpass', sans-serif;

}

.slick-prev:before, .slick-next:before {
    opacity: 1;
    color: #094EFC !important;
    display: none;
}
.slick-dots li button{
    display: none;
}

.prochain-label{
    margin-left: 20px;
    font-size: 19px;
    font-weight: 700;
    color: #1349FC;
    text-align: center;
    font-family: OverPass;
}

.progress {
    background-color: #DFE7F5;
    border-radius: 11px;
    width: 70%;
}

.progress-bar {
    border-radius: 11px;
    background: linear-gradient(45deg, #0C4EFC, #4F3DFF);
}

.btn-participe{
    /* padding: 10px; */
    border: none;
    color: white;
    padding: 5px 25px;
    font-size: 16px;
    font-weight: 300;
    background: transparent linear-gradient(180deg, #0612B7 0%,#1846FC 50%, #0054FC 100%);
    box-shadow: 0 3px 6px #00000029;
    border-radius: 23px;
}


.virgule{
    text-align: left;
    font: 50px Roboto;
    letter-spacing: 0;
    color: #0C4EFC;
    margin-right: 8px;
}

.main-home{
    margin-top: -30px;
    padding: 0;
}

.somme-chiffre{
    font-size: 34px;
    color: rgb(12, 78, 252);
    margin-left: 20%;
    vertical-align: middle;
    font-weight: 900;
    margin-bottom: -7px;
}

.time-img{
    width: 25px;
    margin-top: -5px;
}

.home-title{
    color: #324865;
    font-size: 19px !important;
    font-weight: 900;
}

.blue-mobile{
    background-image: linear-gradient(to bottom, #0612b7, #0c1dc4, #1127d0, #1531dd, #173aea, #163ff0, #1445f6,
    #124afc, #0e4dfc, #084ffc, #0452fc, #0054fc);
    padding: 30px 5px;
    margin: 0 -5px;
}

.color-white{
    color: white !important;
}

.img-carte{
    vertical-align: middle;
    border-style: none;
    width: 40px;
    padding-bottom: 5px;
}
.title-left{
    padding-top: 75px;
    padding-left: 180px !important;
}

.title-left h3{
    font-size: 50px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
}
.ant-btn-background-ghost span{
    color: #fff;
}
.title-left p{
    font-size: 24px;
    color: #fff;
    font-weight: 200;
    margin-top: 30px;
}


.card-txt{
    color: #1D1F39;
    font-size: 13px;
    font-family: 'Poppins';
    padding: 0 15px;
    position: absolute;
    top: 170px;
}

.card-title{
    margin-bottom: 25px;
    position: absolute;
    top: 90px;
    left: 50px;
    right: 50px;
    font-size: 20px;
    color: #1D1F39;
    font-weight: 700;
    margin-top: 20px;
    padding: 0 15px;
}



.card-row{
    margin-top: 100px;
    padding: 0 16%;
}

.card-fonct .ant-card-body {
    box-shadow: 0 11px 20px #0000002b;
    padding: 0;
    height: 305px;
    width: 310px;
    transition: top ease 0.5s;
    position: relative;
    top: 0;
}
.card-fonct .ant-card-body:hover {
    top: -30px;
}

.card-img {
    width: 40px !important;
    padding-top: 25px;
}

.left-corner{
    width: 50px;
    position: absolute;
    bottom: 0;
}

.title-left label{
    width: 100%;
    font-size: 18px;
    color: #fff;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 10px;
}

input.ant-input {
    border-radius: 32px;
    height: 40px;
    padding: 0 15px;
    width: 440px;
}

input.ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1D1F39;
    opacity: 1; /* Firefox */
    font-size: 15px;
    font-weight: 400;
}

button.ant-btn.ant-btn-background-ghost {
    border: 2px solid #fff;
    border-radius: 42px;
    width: 220px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Poppins';
    padding: 0px 20px;
    height: 40px;
    color:#FFFFFF;
}

button.ant-btn.estimation-btn.ant-btn-background-ghost:hover {
    color : #fff;
    border: 2px solid #1D1F39;
}

.form-adre{
    /*width: 440px;*/
    display: flex;
    align-items: center;
}

.phone-right {
    padding-top: 150px;
}
img.phone-home {
    height: 62vw;
    margin-left: -35%;
}
.solut{
    margin-top: 120px;
    margin-bottom: 100px;
}
.card-img.cam{
    width: 90px !important;
}
.card-img.euro{
    width: 60px !important;
}
.main-tp{
    margin-top: 30px;
}
span.asterique {
    color: #fff;
}
@media only screen and (max-width: 1025px) {
    .phone-white {
        padding-left: 0;
        width: 115%;
    }
    .phones {
        display: none !important;
    }
    .collapse-shadow {
        padding-right: 0px;
        margin-top: 60px;
        margin-left: -15px;
        margin-right: -15px;
    }
    .first .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        right: 0;
    }
    .collapse-shadow .ant-collapse-header {
        height: auto;
        padding: 15px !important;
        font-size: 14px;
    }
    .inscription-home .title-hom{
        text-align: center;
    }
    .numbers{
        background-image: url("../../Images/pick_site/Rectangle_blue.png");
        height: 160px;
        background-repeat: no-repeat;
        background-position-y: 0;
        background-position-x: 30px;
        background-size: 170%;
        padding: 0 60px;
    }
    .conta{
        padding: 0 25px !important;
    }
    .count-number {
        font-size: 30px;
    }
    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        font-size: 15px;
    }
    .inscription-home p {
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .facon-livraison h4 {
        font-size: 20px;
    }
    button.btn-contact span{
        font-size: 16px;
    }
    .main-tp{
        margin-top: 55px;
        display: block !important;
    }
    span.anticon.anticon-eye-invisible.ant-input-password-icon {
        transform: scale(2.5);
    }
    .input-register {
        font-size: 14px;
    }
    span.anticon.anticon-eye.ant-input-password-icon {
        transform: scale(2.5);
    }
    .solution-livraison .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        font-size: 15px;
    }
    .estimation{
        text-align : center;
    }
    p.explic-estim {
        margin-top: 25px !important;
        font-size: 8px !important;
    }
    .estimation-div {
        margin-top: 25px;
    }
    button.ant-btn.btn-oui.valider span{
        font-size:15px
    }
    .estimation span{
        font-size: 24px;
    }
    .solution-livraison {
        padding-right: 0;
    }
    .card-row {
        margin-top: 75px;
        padding: 0 55px;
    }
    .card-row.sec {
        margin-top: 5px;
    }
    .phone_white_collapse{
        padding-left: 0;
        width: 115%;
    }

    .card-fonct {
        margin-bottom: 50px;
    }
    .solution-livraison img {
        width: 60px;
        margin-top: 70px;
    }
    .inscription-home{
       padding: 0 50px;
        height: auto;
        padding-bottom: 20px;
    }
    .inscription-home button.ant-btn span{
        font-size: 15px;
    }
    .newsletter {
        height: 400px;
        background-position-y: 0;
        background-position-x: -260px;
        background-size: 160%;
    }
    .newsletter button.ant-btn span{
        font-size: 15px;
    }
    .center.sec {
        margin-top: 35px;
    }
    .solut {
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 0 65px;
    }
    .title-left h3{
        font-size: 35px;
    }
    .propos {
        margin-top: 50px;
    }
    .apropos-txt{
        padding: 0px 70px;
        text-align-last : left;
    }
    .vertical-align {
        padding: 0 30px 0;
        min-height: 0;
        background-image: url("../../Images/back_img_home_pick.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 860px;
        background-position-x: -155px;
        background-position-y: -5px;

    }
    input.ant-input {
        width: 100%;
    }
    button.ant-btn.ant-btn-background-ghost span{
        font-size: 15px;
    }
    .form-adre {
        width: 100%;
        display : block;
    }
    .estimation{
        text-align : center;
    }
    .estimation span{
        font-size: 24px;
    }
    .title-left label{
        margin-top: 30px;
    }
    .home-title{
        font-weight: 800;
        font-size: 15px !important;
    }

    .marg{
        margin-bottom: 20px;
    }
    .phone-right {
        display: none;
    }

    .txt-col {
        font-weight: 400;
        text-align: justify;
    }

    .title-left{
        padding-top: 50px;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
