.column{
    background-image: linear-gradient(to right top, #0612b7, #0c1dc4, #1127d0, #1531dd, #173aea, #163ff0, #1445f6,
    #124afc, #0e4dfc, #084ffc, #0452fc, #0054fc);
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
}

.headerNav h5{
    color: white;
}

.sousMenu{
    background-color: #F6F6F6;
    padding-top: 10px;
}

.dropdown123{
    display: none;
}


